@import '../../scss/imports';

.event-tile {
  background-color: #fff;
  border-radius: $border-radius;
  color: $secondary-text-color;
  cursor: pointer;
  display: grid;
  grid-gap: .25rem;
  grid-template-columns: 1fr;
  margin-bottom: .5rem;
  padding: .5rem;
  position: relative;

  .event-prepaid-status {
    align-items: center;
    background-color: #fff;
    border-radius: $border-radius;
    display: grid;
    grid-gap: .25rem;
    grid-template-columns: .75rem 1fr;
    padding: .125rem;
    width: fit-content;
  }

  .recurring-status {
    --rmd-icon-size: .75rem;

    align-items: center;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .25);
    border-radius: $border-radius;
    display: grid;
    grid-gap: .25rem;
    grid-template-columns: .75rem 1fr;
    padding: .125rem;
    width: fit-content;
  }

  .event-status {
    --rmd-icon-size: .75rem;

    align-items: center;
    border-radius: $border-radius;
    display: grid;
    grid-gap: .25rem;
    grid-template-columns: .875rem 1fr;
    padding: .125rem .25rem;
    width: fit-content;

    &.ghost {
      border: 1px solid rgba(0, 0, 0, .25);
    }
  }

  .event-tile-name {
    align-items: center;
    display: flex;
    font-family: $font-demi;
  }

  .event-tile-description {
    color: $label-color;
    font-family: $font-demi;
  }

  .event-tile-text,
  .event-tile-name,
  .event-tile-description {
    font-size: .75rem;
  }
}
