@import '../../scss/imports';

.search-input-container {
  position: relative;

  input {
    background: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 1rem;
    font-family: $font-regular;
    font-size: .875rem;
    height: 2rem;
    line-height: 2rem;
    min-width: 100%;
    padding-left: .75rem;
    padding-right: 1.75rem;
  }

  span {
    height: 1.5rem;
    position: absolute;
    right: .375rem;
    top: .25rem;
    width: 1.5rem;
  }
}
