.select-input {
  appearance: none;
  background-color: rgba(#000, .05);
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.00016 7.33366L0.333496 0.666992H13.6668L7.00016 7.33366Z' fill='%23666666'/%3E%3C/svg%3E");
  background-position: right .625rem bottom .75rem;
  background-repeat: no-repeat;
  background-size: .75rem auto;
  border: 0;
  border-radius: $border-radius;
  cursor: pointer;
  padding: .75rem 1.625rem .75rem .5rem;
  width: 100%;

  &::-ms-expand {
    display: none;
  }

  &:disabled,
  &[aria-disabled=true] {
    opacity: .5;
  }
}

.select--is-disabled {
  opacity: .8;

  .select__control {
    background-color: rgba(#000, .12) !important;
  }
}

.select__indicator-separator {
  display: none !important;
}

.select__control {
  background-color: $input-bg-color !important;
  border: 0 !important;
  border-radius: $border-radius !important;
  cursor: pointer;

  &--is-focused {
    box-shadow: 0 0 0 1px $primary-color !important;
  }
}

.select__option {
  &--is-focused {
    background-color: rgba($primary-color, .05) !important;
  }

  &--is-selected {
    background-color: $primary-color !important;
  }
}
