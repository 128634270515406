@import '../../scss/imports';

#layout {
  display: flex;

  h1 {
    color: #000;
  }

  .content-container {
    flex-grow: 1;
    padding-right: 1.25rem;
    position: relative;
  }

  .separator {
    margin: 0;
  }
}
