* {
  box-sizing: border-box;
}

body {
  background: $body-bg-color;
}

html,
body {
  color: $secondary-text-color;
  font-family: $font-regular;
  font-size: 100%;
  -webkit-font-smoothing: antialiased;
  height: auto;
  margin: 0;
  min-height: 100vh;
  padding: 0;
  width: 100%;
}

main {
  padding-bottom: 2.5rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
button,
input,
textarea,
html {
  font-family: $font-regular;
  font-weight: normal;
}

hr {
  border: 0;
  border-bottom: 1px solid #dedede;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
}

button,
input {
  appearance: none;
  appearance: none;
  background: transparent;
  border: 0;
  color: inherit;
  font: inherit;
  -webkit-font-smoothing: inherit;
  line-height: normal;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 0;
  width: auto;

  &:-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  &:focus {
    outline: none;
  }
}

.wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 75rem;
  padding: 0 1rem;
  position: relative;
}

.wrapper-full {
  margin: 0;
  max-width: 100%;
  padding: 0;
}

.button {
  height: 2.25rem;
  margin: 0;
  min-width: 3.75rem;
  text-transform: uppercase;

  &.ghost {
    border: 1px solid rgba($text-color, .6);
    color: rgba($text-color, .6);
  }

  &.blue-ghost {
    border: 1px solid $secondary-color;
    color: $secondary-color;
  }

  &.gray {
    background-color: #f4f4f4;
    color: #a3a3a3;
    font-family: $font-demi;
    font-size: 1rem;
  }
}

.dialog-title {
  color: $secondary-text-color;
  font-family: $font-light;
  font-size: 1.5rem;
  font-weight: 600;
  margin: 1rem;
  text-align: center;
}

.dialog-actions {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: 1fr 1fr;
  padding: 1.5rem;
  width: 100%;
}

.text-disabled {
  opacity: .6;
}

.status-indicator {
  align-self: center;
  background-color: #eee;
  border-radius: 50%;
  display: inline-block;
  height: .625rem;
  margin: .125rem .5rem .125rem 0;
  width: .625rem;
}

.y-center {
  align-items: center;
  display: flex;
}

.linear-progress-container {
  height: .25rem;
}
