@import '../../scss/imports';

.event-details-dialog {
  overflow-y: auto;
  position: relative;
  width: 33.25rem;

  .event-details {
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: 1fr;
    padding: 1.5rem;
  }

  .close-button {
    position: absolute;
    right: 1rem;
    top: 1rem;
  }

  .details-list {
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: 1fr;
    margin: 0;
    padding: 0;

    &.sub-list {
      padding-top: .5rem;

      .details-icon {
        align-items: center;
        display: grid;
      }

      .detail-name {
        align-items: center;
        display: grid;
      }

      .integration-source {
        font-family: $font-regular;
      }
    }
  }

  .details-item {
    display: grid;
    grid-gap: 1.5rem;
    grid-template-columns: 1.5rem auto;
  }

  .details-icon {
    .rmd-icon.material-icons {
      font-size: 1.5rem;
    }
  }

  .event-name {
    color: $secondary-text-color;
    font-family: $font-demi;
    font-size: 1.5rem;
    width: calc(100% - 3rem);
  }

  .detail-name {
    color: $secondary-text-color;
    font-family: $font-demi;
    font-size: .875rem;
  }

  .detail-description {
    color: $secondary-text-color;
    font-family: $font-regular;
    font-size: .75rem;
    margin-top: .1875rem;
  }

  .status-icon {
    background-color: #eee;
    border-radius: 50%;
    display: inline-block;
    height: 1.5rem;
    width: 1.5rem;
  }

  .detail-tag {
    background-color: #e5e5e5;
    border-radius: $border-radius;
    display: inline-block;
    margin-top: .5rem;
    padding: .4375rem 1rem;
  }

  .form-field-container {
    align-content: space-around;
    display: grid;
    grid-column-gap: 1rem;
    grid-template-columns: 1.5rem 1fr 1fr;
    justify-content: space-between;
    width: 100%;
  }

  .form-field-label {
    align-items: center;
    color: $secondary-text-color;
    display: inline-flex;
    font-family: $font-demi;
  }

  .form-field-input {
    display: inline-flex;
    justify-content: flex-end;
  }

  .action-container {
    text-align: center;
  }
}
