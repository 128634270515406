@import '../../scss/imports';

.sidebar {
  background: $body-bg-color;
  flex-grow: 0;
  flex-shrink: 0;
  padding: .75rem;
  width: 14.375rem;
  z-index: 1;

  @include max-screen(800px) {
    display: none;
  }

  &.collapsed {
    width: 5rem;
  }
}

.side-menu,
.side-menu-small {
  .rmd-list {
    display: grid;
    grid-row-gap: 1rem;
    grid-template-columns: 1fr;
    padding: 0;
  }

  .rmd-list-item {
    --rmd-list-item-vertical-padding: 0;
    --rmd-list-item-height: 2.25rem;
    border-radius: 100em;
    color: $secondary-text-color;
    font-family: $font-demi;
    position: relative;

    .rmd-list-tile--active {
      background: rgba(#1baff5, .2);
      color: $primary-color;
    }

    &.active {
      background: rgba(#1baff5, .2);
      color: $primary-color;

      .rmd-icon,
      .rmd-text,
      svg {
        color: $primary-color;
      }
    }
  }
}
