@import '../../scss/imports';
@import '~react-day-picker/dist/style.css';

.date-time-range-picker-container {
  display: grid;
  grid-gap: .5rem;
  grid-template-columns: 1fr;
}

.date-time-range-picker {
  align-items: center;
  display: grid;
  grid-gap: .25rem;
  grid-template-columns: auto 2rem auto;
  width: 100%;

  &.multiline {
    grid-template-columns: auto;
  }

  .time-select {
    --rmd-button-text-horizontal-padding: .25rem;
    background: $input-bg-color;
    border-color: $input-bg-color;
    border-radius: .25rem;
    min-width: 6rem;

    &.rmd-select--disabled {
      background: rgba(#b5b5b5, .3);
    }
  }

  .selected-time {
    color: $secondary-text-color;
    font-family: $font-regular;
    font-size: .875rem;
  }

  .date-time-range-picker-timezone,
  .range-separator {
    align-self: center;
    color: #7a7a7a;
    text-align: center;
  }
}

.date-time-range-picker-error {
  color: $priority-color;
  font-size: .875rem;
}

.date-time-picker {
  --rdp-accent-color: #{$primary-color};
  --rdp-outline: #{$primary-color};
  --rdp-outline-selected: #{$primary-color};
  --rdp-background-color: #{rgba($primary-color, .05)};

  display: grid;
  grid-gap: .5rem;
  grid-template-columns: 8.125rem 6rem 2.25rem;

  &.with-label {
    grid-template-columns: 2.125rem 8.125rem 6rem 2.25rem;
  }

  .date-time-picker-label {
    color: #000;
    font-size: .875rem;
    padding: .375rem 0;
  }

  .date-time-picker-input {
    border-bottom: 1px solid #dadada;
    font-size: .875rem;
    line-height: 1rem;
    margin-right: .5rem;
    padding: .375rem 0;
    width: 8.125rem;
  }

  .popper-container {
    background: #fff;
    border-radius: $border-radius;
    box-shadow: 0 .0625rem .625rem rgba(0, 0, 0, .04), 0 .25rem .3125rem rgba(0, 0, 0, .06), 0 .125rem .25rem -.0625rem rgba(0, 0, 0, .09);
    z-index: 1;
  }
}

.date-time-picker-input-disabled {
  color: rgb(127, 127, 127);
  opacity: .7;
}

.select--is-disabled {
  opacity: .7;
}

.date-time-range-picker-disabled {
  .date-time-range-picker-timezone {
    opacity: .7;
  }
}
