@import '../../scss/imports';

.top-action-bar {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 0;
  z-index: 1;

  .date-controls {
    align-items: center;
    display: flex;
    gap: .25rem;

    h4 {
      display: inline-block;
      font-size: 2rem;
      margin: 0;
    }

    .date-nav,
    h4 {
      color: $secondary-text-color;
    }
  }

  .action-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    gap: .5rem;
    justify-content: flex-end;

    .search-input-container {
      max-width: 11.875rem;
    }

    .create-event-button {
      background-color: #a3e5ed;
      color: $secondary-text-color;
      height: 2rem;
      line-height: 2rem;
      text-transform: capitalize;
      white-space: nowrap;

      &:hover {
        background-color: rgba($primary-color, .7);
      }
    }
  }
}
